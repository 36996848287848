<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editChannel')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createChannel')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-select-group v-model="form.api_type"
                                         :options="getSourceTypes()"
                                         name="api_type"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                </b-row>

                <b-row v-if="['otto-market','magento','amazon','woocommerce','ebay'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.source_host"
                                        name="source_host"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row v-if="['shopify'].includes(form.api_type)">
                    <b-col cols="12">
                        <b-form-group :label="$t(labelPrefix+'source_host')">
                            <b-input-group size="lg">
                                <b-input-group-prepend is-text>
                                    https://
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="form.source_host"
                                    name="source_host"
                                    validate="required"
                                ></b-form-input>
                                <b-input-group-append is-text>.myshopify.com</b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="['otto-market','magento','amazon','woocommerce'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.source_user"
                                        name="source_user"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['ebay'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.source_user"
                                        name="ebay.client_id"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row v-if="['otto-market','magento','amazon','woocommerce'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.source_key"
                                        name="source_key"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        type="password"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['ebay'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.source_key"
                                        name="ebay.client_secret"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['shopify'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.source_key"
                                        name="source_key"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        type="password"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row v-if="['amazon'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.eapi_user"
                                        name="eapi_user"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['amazon'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.eapi_key"
                                        name="eapi_key"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['amazon'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.eapi_token"
                                        name="eapi_token"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['ebay'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.eapi_key"
                                        name="ebay.code"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['ebay'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.eapi_user"
                                        name="ebay.runame"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row v-if="['ebay'].includes(form.api_type)">
                    <b-col cols="12">
                        <zw-input-group v-model="form.eapi_token"
                                        name="ebay.eapi_token"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row v-if="['otto-market','magento','amazon','woocommerce','ebay','shopify'].includes(form.api_type)">
                    <b-col>
                        <b-form-group :label="$t(labelPrefix + 'is_enable')">
                            <b-form-checkbox
                                v-model="form.is_enable"
                                switch
                                name="is_enable"
                                value="1"
                                unchecked-value="0"
                            >
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Channel-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
            },
            form: {},
            labelPrefix: 'multichannel.settings.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getSourceTypes']),
        ...mapGetters('Channel', ['getSource']),
        shown() {
            const channel = this.$store.dispatch('Channel/fetchSource', this.payload.id)

            Promise.all([channel])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getSource()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Channel/saveSource', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>